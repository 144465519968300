<template>
    <div class="center">
        <h1>404</h1>
        <h2>Page non trouvée</h2>
        <p>
            <a href="/#/accueil">Retourner à l'accueil ?</a>
        </p>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
}

</script>

<style scoped>
.center {
    margin: auto;
    text-align: center;
}

h1 {
    color: var(--border);
    font-size: 3em;
}
h2 {
    font-size: 2em;
}
p {
    font-size: 1.5em;
}
</style>